const debounce = (callback, wait = 300) => {
    let timeout;
 
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => callback(...args), wait);
    };
};

export {
    debounce
}