<script>
    import { onMount } from 'svelte';
    import { get } from 'svelte/store'
    import { getSoundAssets } from '../utils/audio/soundsAssets';
    import { playSound } from '../utils/audio/soundUtils';
    import { showWallet, burnActive, burnError, mintActive, revealActive } from '../utils/Store'

    let showPopup = true
    let audioEnabled = false
    let listener = false

    /** @type HTMLAudioElement */
    let audioBG
    let iconContainer

    // Props
    export let onFirstClick = null

    let src = '/assets/audio/website-homepage-main-loop-v2.mp3'

    onMount(() => {
        mintActive.subscribe(onEnableBurn)
        revealActive.subscribe(onEnableBurn)
        burnActive.subscribe(onEnableBurn)
        burnError.subscribe(onEnableBurn)
    })

    function onEnableBurn(val) {
        if (val) {
            closePopup()
        }
    }

    function enableSoundClick() {
        toggleAudio()
        closePopup()

        // Enable global clicks audio
        let soundClickButtons = document.querySelectorAll('.sound-click')
        const { mintBtnClick } = getSoundAssets()
        soundClickButtons.forEach(button => {
			button.addEventListener('click', () => {
				playSound(mintBtnClick);
			})
		})

        // callback on first click
        onFirstClick && onFirstClick()
    }

    function toggleAudio() {
        audioEnabled = !audioEnabled

        const audioEvent = new CustomEvent("audioChange", {detail: {enabled: audioEnabled}})
        window.dispatchEvent(audioEvent)

        const isRevealPage = get(revealActive)


        if (audioEnabled && !isRevealPage) {
            audioBG.play()
        } else {
            audioBG.pause()
        }

        // Handle global audio on Howler library
        if (window['Howler']) {
            window['Howler'].mute(!audioEnabled);
        }

        

        // Always enable audio control only when tab is active??
        !listener && document.addEventListener("visibilitychange", function(e) {
            listener = true
            
            const isPlaying = iconContainer.children.item(0).classList.contains('onMusicSmall');
            
            const isReveal = get(revealActive)
            if (!isReveal) {
                if (document.visibilityState === 'visible') {
                    isPlaying && audioBG.play()
                } else {
                    isPlaying && audioBG.pause()
                }
            }

        });
    }


    function offAudioAndClose() {
        window['Howler'].mute(true);
        closePopup();

        // callback on first click
        onFirstClick && onFirstClick()
    }

    function closePopup() {
        showPopup = false
    }
</script>

{#if showPopup}
<div class="popup-sound position-popup">
    <p class="font-type-regular text-[7rem] md:text-[1.67rem] uppercase mb-64 md:mb-14">Would you like to turn on the sound to get a better experience?</p>
    <div class="yorn flex items-center justify-around">
        <button class="ans offMusic custom-cursor" on:click={offAudioAndClose}>
            <span>no</span>
            <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M25.5 13.4995L30 17.9995M30 17.9995L34.5 22.4995M30 17.9995L34.5 13.4995M30 17.9995L25.5 22.4995M9.19558 24.1392L17.1281 29.8053C18.1209 30.5144 19.5 29.8048 19.5 28.5847V7.41427C19.5 6.19421 18.1209 5.48452 17.1281 6.19367L9.19558 11.8598C9.06838 11.9506 8.91597 11.9995 8.75965 11.9995L3 11.9995C2.17157 11.9995 1.5 12.6711 1.5 13.4995V22.4995C1.5 23.3279 2.17157 23.9995 3 23.9995H8.75965C8.91597 23.9995 9.06838 24.0483 9.19558 24.1392Z"
                    stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </button>
        <button class="ans yes onMusic custom-cursor" on:click={enableSoundClick}>
            <span>yes</span>
            <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M25.5 13.4995C26.4279 14.659 27 16.2474 27 17.9995C27 19.7516 26.4279 21.3399 25.5 22.4995M30 8.99948C31.8557 11.3186 33 14.4953 33 17.9995C33 21.5036 31.8557 24.6804 30 26.9995M9.19558 24.1392L17.1281 29.8053C18.1209 30.5144 19.5 29.8048 19.5 28.5847V7.41427C19.5 6.19421 18.1209 5.48452 17.1281 6.19367L9.19558 11.8598C9.06838 11.9506 8.91597 11.9995 8.75965 11.9995L3 11.9995C2.17157 11.9995 1.5 12.6711 1.5 13.4995V22.4995C1.5 23.3279 2.17157 23.9995 3 23.9995H8.75965C8.91597 23.9995 9.06838 24.0483 9.19558 24.1392Z"
                    stroke="white" stroke-width="2" stroke-linecap="round" />
            </svg>
        </button>
    </div>
</div>
{:else}
<div class="audio-icon" class:activeWallet={$showWallet} class:z-50={$showWallet} bind:this={iconContainer}>
    {#if audioEnabled}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <svg class="onMusicSmall" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" on:click={toggleAudio}>
            <path
                d="M25.5 13.4995C26.4279 14.659 27 16.2474 27 17.9995C27 19.7516 26.4279 21.3399 25.5 22.4995M30 8.99948C31.8557 11.3186 33 14.4953 33 17.9995C33 21.5036 31.8557 24.6804 30 26.9995M9.19558 24.1392L17.1281 29.8053C18.1209 30.5144 19.5 29.8048 19.5 28.5847V7.41427C19.5 6.19421 18.1209 5.48452 17.1281 6.19367L9.19558 11.8598C9.06838 11.9506 8.91597 11.9995 8.75965 11.9995L3 11.9995C2.17157 11.9995 1.5 12.6711 1.5 13.4995V22.4995C1.5 23.3279 2.17157 23.9995 3 23.9995H8.75965C8.91597 23.9995 9.06838 24.0483 9.19558 24.1392Z"
                stroke="white" stroke-width="2" stroke-linecap="round" />
        </svg>
    {:else}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <svg class="offMusicSmall" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" on:click={toggleAudio}>
            <path
                d="M25.5 13.4995L30 17.9995M30 17.9995L34.5 22.4995M30 17.9995L34.5 13.4995M30 17.9995L25.5 22.4995M9.19558 24.1392L17.1281 29.8053C18.1209 30.5144 19.5 29.8048 19.5 28.5847V7.41427C19.5 6.19421 18.1209 5.48452 17.1281 6.19367L9.19558 11.8598C9.06838 11.9506 8.91597 11.9995 8.75965 11.9995L3 11.9995C2.17157 11.9995 1.5 12.6711 1.5 13.4995V22.4995C1.5 23.3279 2.17157 23.9995 3 23.9995H8.75965C8.91597 23.9995 9.06838 24.0483 9.19558 24.1392Z"
                stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    {/if}
</div>
{/if}
<audio id="background-audio" bind:this={audioBG} src={src} loop></audio>

<style>
    .position-popup {
        @apply fixed bottom-[90rem] left-64 md:bottom-8 md:left-10
    }
    .popup-sound {
        @apply w-[80vw] flex flex-col border border-white px-40 pt-32 pb-64 bg-black overflow-hidden md:px-7 md:pt-8 md:pb-16 md:w-[33rem] md:tracking-widest z-40;
    }
    .audio-icon {
        @apply fixed bottom-52 left-48 w-56 md:bottom-36 md:top-auto md:right-auto md:left-14 md:w-12
    }

    .ans {
        @apply flex items-center justify-center font-chakra-bold relative border-none
    }

    .ans span {
        @apply text-9xl text-[12rem] uppercase mr-20 md:text-5xl md:mr-4
    }

    .ans svg {
        @apply w-[22%]
    }

    .ans.yes span, .ans.yes svg {
        @apply invert
    }

    .ans.yes:after {
        content: "";
        background: url('/assets/images/framesound.svg') no-repeat;
        background-size: 100% 100%;
        @apply absolute z-[-1] translate-x-0 w-[50rem] h-[20rem] md:w-[14rem] md:h-[6rem]
    }
</style>