import { BigNumber, ethers } from 'ethers';
import ContractABI from '../mint/data/ABI-mint-live2.json';
import ProxyContractABI from '../mint/data/ABI-upgrade-contract.json';
import { getTokenNFIC, saveTokenWhitelist, revealTokens } from './Backend';
import { burnError, burnActive, isFounder, isReferral, isPublicMintOpen } from './Store'
import { createWeb3Modal } from './Web3Modal';
import { get } from 'svelte/store';
import { setupInertia } from './Inertia';

// const contractAddress = '0xBE3EE8f62e9E055b8118A1EAC4feb0903B04E3c7'; // Burn Contract
// const contractAddress = '0x2f17d60163AfCe9c00A0f7A893097215D1Aa65F0'; // Mint test contract v0.1 - low prices for local testing
// const contractAddress = '0x148C28F13062f1A204F95B718Db69F018bE836Db'; // Mint test contract - 18 Feb 06:48pm -> Updated prices and dates
// const contractAddress = '0x9c1b6D60957CfD9A9Cf44e146eEe7cB880485ff6'; // Mint test contract - 19 Feb 05:49pm -> Ready for test
// const contractAddress = '0x05260f1a5467ef4efe43ac039ceb95955a47a60d'; // Mint test contract - 20 Feb 04:07pm -> new friends concept
// const contractAddress = '0xf8bf9c10e336b8977b87386959686d9403c4f832'; // Mint test contract - 21 Feb 12:45pm -> fixes on mint amount
// const contractAddress = '0xC5178098898d3e095cFC8e923114302Ad5f76EE8'; // Mint Live contract - 21 Feb 03:21pm
const contractAddress = '0xb52639DeEe545C3E7E9D5a2cfa10d335b133c8d1'; // Mint Live contract 2 - 21 Feb 04:09pm -> OK
// const contractAddress = '0x87DE6eFd08e93a0B9C4fe3d3008F67329442f829'; // Mint test contract 27 Feb
const upgradeContractAddress = '0x8Cfa6F29930E6310B6074baB0052c14a709B4741' // proxy 28 Feb 00:39am


/** @type {ethers.Contract} */
let contract = null

/** @type {ethers.Contract} */
let proxyContract = null

/** @type Array<String> */
let accounts = []

// index on the accounts array
let indexAccount = 0

// Gas price on the moment of the wallet connection
let gasGwei = 0

let connectTime = Date.now()

let isDebug = location.hostname==='localhost' || location.search.includes('odlabs-debug=') || false

const dec = window.atob;

// const burnAddress = '0x000000000000000000000000000000000000dEaD'
// const defaultBurnAddress = '0x889b998DBaB13C897d6B572587bfAdC7A5B8688E'
const defaultBurnAddressB64 = dec('TUhnek16TXpNek16TXpNek16TXpNek16TXpNek16TXpNek16TXpNek16TXpNek16TXpNek16TXoK')

/** ONLY FOR DEBUG **/
async function showAllTokens() {
    const totalSupply = await contract.totalSupply()
    const total = totalSupply.toNumber()
    console.log('Loading total of tokens:', total)

    const ids = []
    for (let i=0; i<total; i++) {
        try {
            let id = await contract.ownerOf(i)
            ids.push(id)
        } catch(ex) {
            console.error(i, 'Error ->', ex.message)
        }
    }
    console.log('ids:', ids)
}


let web3Modal
let web3ModalSubscribed = false
let lastConnectionState = false
let ethersProvider
async function getConnection(onChangeConnection) {

    if (!web3Modal) {
        web3Modal = createWeb3Modal()

        if (!web3ModalSubscribed) {
            web3Modal.subscribeState(async newState => {
                web3ModalSubscribed = true
                const isConnected = web3Modal.getIsConnected()
                if (isConnected === lastConnectionState) {
                    // console.log('connection not changed...', lastConnectionState)
                    return
                }
                lastConnectionState = isConnected

                isConnected && isDebug && console.log('Connected to:', contractAddress)

                try {
                    const walletProvider = web3Modal.getWalletProvider()
                    // console.log('walletProvider?', walletProvider)

                    if (!walletProvider) {
                        onChangeConnection(isConnected)
                        return
                    }

                    // on Mobile we have walletProvider.accounts
                    // on Desktop we have walletProvider.selectedAddress
                    if (isConnected) {
                        accounts = walletProvider.accounts || [walletProvider.selectedAddress]
                        location.hostname==='localhost' && console.log('Your accounts:', accounts)

                        ethersProvider = new ethers.providers.Web3Provider(walletProvider)
                        const signer = await ethersProvider.getSigner()

                        contract = new ethers.Contract(contractAddress, ContractABI, signer);

                        proxyContract = new ethers.Contract(upgradeContractAddress, ProxyContractABI, signer)
                        location.hostname==='localhost' && console.log('C:', contract)

                        checkIsFounder()
                        checkIsReferred()
                        checkIsPublicOpen()
                        checkGasPrice()

                        connectTime = Date.now()
                    }
                    onChangeConnection(isConnected)
                } catch(ex) {
                    console.error('Wallet Error', ex)
                    onChangeConnection(isConnected)
                }
            })
        }
    }

    web3Modal.open()
}

async function checkGasPrice() {
    const gas = await ethersProvider.getGasPrice()
    // console.log('reading gas...', gas, gas.toBigInt(), gas.toNumber())
    gasGwei = parseFloat(ethers.utils.formatUnits(gas, 'gwei'))
    console.log('Gas:', gasGwei)
}


async function checkIsFounder() {
    try {
        if (isDebug && window.location.search.includes('inertia=')) {
            console.log('isFounder?', true, ' - temp inertia')
            isFounder.set(true)
            return
        }
        const isUserFounder = await contract.founders(getUserAddress())
        isDebug && console.log('isFounder?', isUserFounder)
        isFounder.set(isUserFounder)
    } catch(ex) {
        isFounder.set(false)
    }
}

async function checkIsReferred() {
    try {
        const inviter = await contract.inviter(getUserAddress())
        let isUserReferred = false
        if (inviter) {
            isUserReferred = inviter!=='0x0000000000000000000000000000000000000000'
            isDebug && console.log('isReferred?', isUserReferred)
        }
        isReferral.set(isUserReferred)
    } catch(ex) {
        isReferral.set(false)
    }
}


async function checkIsPublicOpen() {
    try {
        const publicPaused = (await contract.paused()) && (await contract.whitelistmint())
        isPublicMintOpen.set(!publicPaused)
    
        if (!publicPaused) {
            // setupInertia(contract)
        }
    } catch(ex) {
        console.error(ex.reason || ex.message || ex)
        isPublicMintOpen.set(false)
        return false
    }
}


function getIsFounder() {
    return get(isFounder)
}


function getIsReferred() {
    return get(isReferral)
}


/** Check if the current user has the token or not in our lambda server */
async function userHasToken() {
    if (!accounts) {
        console.warn('Please connect your wallet!')
        return
    }
    let userAddress = accounts[indexAccount]

    // Search for the token index in the array of tokens
    const userTokens = await getTokenNFIC( userAddress )

    return userTokens && userTokens.length > 0
}

/**
 * Returns the user address from the connected wallet
 */
function getUserAddress() {
    try {
        if (web3Modal) {
            return web3Modal.getAddress()
        }
    
        if (!accounts) {
            console.warn('Please connect your wallet!')
            return
        }
    
        let userAddress = accounts[indexAccount]
        return userAddress
    } catch(ex) {
        contract = null
        return null
    }
}


/**
 * Run the transfer transaction to burn the initial token and save te address into the whitelist on the lambda server
 */
async function burnToken() {
    let lastTokenId = ''
    try {
        if (!accounts || !contract) {
            console.warn('Please connect your wallet!')
            return
        }

        let userAddress = accounts[indexAccount]

        let userTokens = await getTokenNFIC( userAddress )
        
        // console.log('Do you have the token?', userTokens.length)
        if (userTokens && userTokens.length > 0) {
            // console.log('Pending transfer to burn this token...')
            lastTokenId = userTokens[0].id
            // console.log(lastTokenId)

            const burnAddress = import.meta.env.VITE_BURN_ADDRESS || dec(defaultBurnAddressB64)
            const txResponse = await contract.transferFrom(userAddress, burnAddress, lastTokenId);
            console.log('burned')
            isDebug && console.log(txResponse)
            
            // Wait for the transaction to be mined
            const txReceipt = await txResponse.wait();
            isDebug && console.log('Transaction mined:', txReceipt);

            // Process the transaction receipt
            if (txReceipt.status === 1) {
                // console.log('Transaction succeeded');
                // console.log("new in whitelist:", userAddress)
                saveTokenWhitelist(userAddress)

                // If your contract emits events, they can be found in the events array of the receipt
                // for (const event of txReceipt.events) {
                //     console.log('Event:', event);
                // }

                return true
            } else {
                console.error('Transaction failed');
                return false
            }
        } else {
            console.log("You don't have the token!")
            console.warn("Access Denied, you don't have the token")
            // TODO: enable the other access??
            burnError.set(true)
            burnActive.set(false)
            return false
        }
    } catch (error) {
        console.error('BURNING ERROR [%s]', lastTokenId, error);
    }

    return false
}



/**
 * Called form the UI to run the mint on the given list of addresses
 * @param {Number} mintAmount
 * @param {String[]} referralWallets
 */
async function mintLulus(mintAmount, referralWallets) {
    if (!contract) {
        alert('Please connect your Wallet!')
        return false
    }
    if (!getUserAddress()) {
        alert('Please connect your Wallet!')
        return false
    }
    try {
        const pricePerMint = await getMintPrice()
        
        // const _cost = ethers.utils.formatEther(pricePerMint);
        // const _total = ethers.utils.parseEther(`${_cost}`).mul(mintAmount);
        const _total = pricePerMint.mul(mintAmount);
        // console.log('Amount:', mintAmount, ' - price:', pricePerMint, ' - total:', _total)
    
        let txMint
        isDebug && console.log('Start Minting...', _total)
        if (getIsFounder() && referralWallets) {
            // Founders minting and referral addresses
            txMint = await contract.mintFounders(mintAmount, referralWallets, {
                value: _total,
                // gasPrice: ethers.utils.parseUnits("100", "gwei"),
                // gasLimit: 7000000
            })
        } else if (getIsReferred()) {
            // Standard users flow
            txMint = await contract.mintfriend(mintAmount, {
                value: _total,
                // gasPrice: ethers.utils.parseUnits("100", "gwei"),
                // gasLimit: 7000000
            })
        } else {
            // open whitelisted and public mint
            txMint = await contract.mint(mintAmount, {
                value: _total,
                // gasPrice: ethers.utils.parseUnits("100", "gwei"),
                // gasLimit: 7000000
            })
        }

        console.log('Mint OK... Waiting transaction confirmation...', txMint.hash)
        if (txMint) {
            const tx_hash = await txMint.wait();
            console.log('Confirmed!', tx_hash)
            // console.log(`Transaction confirmed in block ${txMint.bloc}`);
            // console.log(`Gas used: ${tx_hash.gasUsed.toString()}`);
        }
    
        return true
    } catch(ex) {
        console.error('Mint Error:', ex)
        throw ex
        // return false
    }
}



/**
 * Validate if a given address is already in the contract whitelist.
 * The address could be a founder, a referral, or a open whitelisted wallet
 * 
 * @param {String} address 
 * @returns {Promise<boolean>} true or false if the wallet is in the contract whitelist
 */
async function isAddressOnWhitelist(address) {
    if (!contract) {
        console.warn('Please connect your wallet!!')
        return false
    }

    if (isDebug && window.location.search.includes('inertia=')) {
        console.log('debug whitelist with inertia')
        return true
    }

    try {
        return await contract.whitelisted(address)
    } catch(ex) {
        console.error(ex.reason || ex.message)
        // NO matter the error, keep boolean return consistency
        return false
    }
}


/**
 * Return an object with two values: wallets and balanceToClaim.
 * 
 * The balanceToClaim shows the amount of ETH that the current user can claim
 * The wallets array contains the list of all referred wallets created when a founder minted
 */
async function getMintedAddresses() {
    if (!contract) {
        alert('Please connect your wallet!')
        return {}
    }
    const address = getUserAddress()
    const queryData = await contract.founder_data(address)

    /** @type {String[]} */
    const list = queryData['_whitelistedAddresses']

    // Skip empty wallets
    const cleanList = list.filter(ad => !ad.startsWith('0x000000000000000'))

    const promises = cleanList.map(async address => {
        /** @type {BigNumber} */
        const mintedAmount = await contract.hwminted(address)
        return {
            address,
            minted: mintedAmount.toNumber()  // What value goes here??
        }
    })
    const wallets = await Promise.all(promises)

    /** @type {BigNumber} */
    const balanceToClaim = queryData['reward']

    return {
        wallets,
        balanceToClaim
    }
}


/**
 * Return the mint price in wei
 * @returns {Promise<BigNumber>} wei price
 */
async function getMintPrice() {
    if (!contract) {
        console.warn('Please connect your wallet!!')
        return BigNumber.from(0)
    }

    // const user = getUserAddress()
    let priceWei = BigNumber.from(0)

    if (getIsFounder()) {
        priceWei = await contract.founderCost()
    } else if (getIsReferred()) {
        priceWei = await contract.friendCost()
    } else {
        const address = getUserAddress()
        const isWhitelisted = await isAddressOnWhitelist(address)
        if (isWhitelisted) {
            priceWei = await contract.whitelistCost()
        } else {
            priceWei = await contract.cost()
        }
    }
    
    // const eth = ethers.utils.formatEther(priceWei)
    return priceWei
}



/**
 * Calculate the MAX Amount of Mint allowed for the current user
 * @returns 
 */
async function getMaxMintAmount() {
    if (!contract) {
        console.warn('Please connect your wallet!!')
        return 0
    }

    let max = 0
    if (getIsFounder()) {
        max = await contract.maxFounderAmount()
    } else {
        max = await contract.maxMintAmount()
    }

    const address = getUserAddress()
    let mintedAmount = await contract.hwminted(address)

    return max - mintedAmount
}

async function getTotalSupply() {
    if (!contract) {
        console.warn('Please connect your wallet!!')
        return 0
    }

    try {
        const total = await contract.totalSupply()
        return parseInt(total.toString())
    } catch(ex) {
        console.error('Contract error:', ex.reason || ex.message)
        return 0
    }
}

async function getMaxSupply() {
    if (!contract) {
        console.warn('Please connect your wallet!!')
        return 0
    }

    try {
        const total = await contract.maxSupply()
        return parseInt(total.toString())
    } catch(ex) {
        console.error('Contract error:', ex.reason || ex.message)
        return 3333
    }
}


/**
 * TODO: Implement rewards claiming
 */
async function claimRewards() {
    if (!contract) {
        console.warn('Please connect your wallet!!')
        return false
    }

    const tx = await contract.Founderwithdraw({ value: 0 })
    
    if (tx && tx.wait) {
        await tx.wait()
    }

    return true
}


async function getTokensMinted() {
    if (!contract) {
        console.warn('Please connect your wallet!!')
        return false
    }

    const userAddress = getUserAddress()
    const tokens = await contract.walletOfOwner(userAddress)
    return tokens
}


async function isGasUnderThreshold() {
    const now = Date.now()
    // cache gas price per 1 minute since wallet connection
    if (now - connectTime > 1000 * 60) {
        // console.log('Checking gas price:', gasGwei)
        await checkGasPrice()
    }

    let limitSponsoredGas = 1
    if (window.location.search.includes('maxGas')) {
        const searchParams = new URLSearchParams(window.location.search);
        const thresholdParam = searchParams.get('maxGas')
        limitSponsoredGas = parseFloat(thresholdParam)
    }

    // 50 and below is sponsored
    // 51 and above customer pays
    const chain = web3Modal.getChainId()
    if (chain===1) {
        return gasGwei < 50
    } else {
        return gasGwei < limitSponsoredGas
    }
}


/**
 * 
 * @param {Number} tokensAmount 
 */
async function migrateContract(tokensAmount) {

    try {
        const userAddress = getUserAddress()
        const isApproved = await contract.isApprovedForAll(userAddress, upgradeContractAddress)
    
        if (!isApproved) {
            const tx1 = await contract.setApprovalForAll(upgradeContractAddress, true)
            console.log('Result tx1:', tx1)
            if (tx1 && tx1.wait) {
                const resTx1 = await tx1.wait()
                console.log('Wait 1', resTx1)
            }
        }
        
        // console.log('Backend call', userAddress, tokensAmount)
        if (tokensAmount===0) {
            console.log('Transfering all lulus to the reveal contract...')
        } else {
            console.log('Transfering [%d] lulus to the reveal contract...', tokensAmount)
        }
        const backResponse = await revealTokens(userAddress, tokensAmount)
        if (!backResponse) {
            return false
        }
    
        console.log('Done!')
    
        return true
    } catch(ex) {
        console.error('Reveal Contract Error:', ex.reason || ex.message)
        return false
    }

}


async function migrateFront(tokensAmount) {
    const userAddress = getUserAddress()

    const isApproved = await contract.isApprovedForAll(userAddress, upgradeContractAddress)
    
    if (!isApproved) {
        const tx1 = await contract.setApprovalForAll(upgradeContractAddress, true)
        console.log('Waiting for approval...')
        if (tx1 && tx1.wait) {
            const resTx1 = await tx1.wait()
            console.log('Wait 1', resTx1)
        }
    }

    let tx2 = null
    if (tokensAmount===0) {
        console.log('Transfering all lulus to the reveal contract...')
        tx2 = await proxyContract.burnForRevealAll(userAddress)
    } else {
        const allTokenIds = await contract.walletOfOwner(userAddress)
        const tokenIds = allTokenIds.slice(0, tokensAmount)
        
        console.log('Transfering [%d] lulus to the reveal contract...', tokensAmount, tokenIds)
        tx2 = await proxyContract.burnForReveal(userAddress, tokenIds)
    }

    if (tx2 && tx2.wait) {
        await tx2.wait()
    }
    console.log('Reveal finished!!')
}


export default {
    getIsFounder,
    getIsReferred,
    getConnection,
    getUserAddress,
    userHasToken,
    burnToken,
    showAllTokens,
    mintLulus,
    isAddressOnWhitelist,
    getMintedAddresses,
    getMintPrice,
    getMaxMintAmount,
    getTotalSupply,
    getMaxSupply,
    claimRewards,
    getTokensMinted,
    isGasUnderThreshold,
    migrateContract,
    migrateFront
}