import { writable, readable } from 'svelte/store'

// Current section active/visible on viewport
export const activeSection = writable(0)

// Total scroll sections
export const totalSections = writable(11)

// Low Power Mode on mobile devices
export const showLowPowerMode = writable(false)

// Flag when metamask wallet is connected
export const isWalletConnected = writable(false)

// Burn and Mint States
export const burnError = writable(false)
export const burnActive = writable(false)
export const mintActive = writable(false)
export const revealActive = writable(false)

// Flag to control mint founder flow
export const isFounder = writable(false)
export const isReferral = writable(false)

export const showMintSuccessful = writable(false)


// Percentage of inertia
export const inertiaAmount = writable(100)

// Enabled when no one has minted after 100 minutes, so there is no more inertia
export const isInertiaFinished = writable(false)

// Enabled when users minted all avaialbles lulus, so we don't have more to mint
export const isInertiaCompleted = writable(false)


// Debug/Development Flag
let tmpDebug = location.hostname==='localhost' || location.search.includes('odlabs-debug=') || location.hostname.includes('192.168.') || false
export const isDebug = readable(tmpDebug)

// rules to show wallet or not when we are on the odlabs website
const getWalletOpen = () => {
    if (window.location.hostname==='odlabs.io') {
        const currentTime = new Date().getTime();
        const closeBurnTime = new Date("2024-02-19T15:33:00-05:00").getTime()
        const openMintTime = new Date("2024-02-21T15:33:00-05:00").getTime()
    
        // if is burn closed
        if (currentTime > closeBurnTime) {

            // after burn is closed, check if the mint time is opened
            if (currentTime > openMintTime) {
                // TODO: Check how wer are going to handle inertia
                return true
            }

            return false
        }
    }

    return true
}
// export const showWallet = writable(getWalletOpen())
export const showWallet = writable(true)


// GuestList / Whitelist Mint Group 3
const checkWhitelistMintOpen = () => {
    const currentTime = new Date().getTime();
    const publicMintTime = new Date("2024-02-21T16:33:00-05:00").getTime()
    return currentTime > publicMintTime
}
export const isWhitelistMintOpen = writable(checkWhitelistMintOpen())


// Public Mint Group 4
const checkPublicMintOpen = () => {
    if (window.location.search.includes('inertia=')) {
        const strValue = window.location.search.replace('inertia=', '')
        if (strValue==='0' || strValue==='100') {
            inertiaAmount.set(parseInt(strValue))
        }
        return true
    }
    
    const currentTime = new Date().getTime();
    const publicMintTime = new Date("2024-02-21T19:33:00-05:00").getTime()
    return currentTime > publicMintTime
}
export const isPublicMintOpen = writable(checkPublicMintOpen())