
// https://tokens-api.odlabs.io
const TOKEN_API_URL = import.meta.env.VITE_TOKEN_API_URL || 'http://localhost:3000/local/tokens-api'

let attempts = 0

/**
 * @typedef TokenIDResponse
 * @property {String} id
 * @property {String} [address]
 * 
 * @param {String} address - User wallet address
 */
export async function getTokenNFIC(address) {
    try {
        const response = await fetch(`${TOKEN_API_URL}?address=${address}`);

        const res = await response.json();

        attempts = 0
        if (res && res.tokens) {
            /** @type Array<TokenIDResponse> | null */
            const list = res.tokens
            return list
        }
    } catch(ex) {
        console.error('Token API Error', ex.message)
        if (ex.message?.includes('Failed to fetch')) {
            if (attempts < 3) {
                attempts++
                return new Promise(resolve => {
                    setTimeout(() => {
                        getTokenNFIC(address).then(res => { resolve(res) }).catch(err => { resolve(null) })
                    }, 500)
                })
            } else {
                console.warn('Backend could not be reached, sorry!')
            }
        }
    }

    return null
}

/**
 * @param {String} address 
 */
export async function saveTokenWhitelist(address) {
    try {
        const response = await fetch(`${TOKEN_API_URL}/?action=saveAddress`, {
            method: 'POST',
            body: JSON.stringify({ address })
        });
        
        const res = await response.json();
        if (res && res.tokens) {
            /** @type Array<TokenIDResponse> | null */
            const list = res.tokens
            return list
        }
    } catch(ex) {
        console.error('Token API Error', ex.message)
    }

    return null
}


/**
 * @typedef UserAddress
 * @property {String} address
 * @property {String} createdAt
 * 
 * @param {String} address 
 * @returns 
 */
export async function isOnWhitelist(address) {
    try {
        const response = await fetch(`${TOKEN_API_URL}?action=findWhitelist&address=${address}`);
        
        const res = await response.json();
        if (res && res.user) {
            /** @type UserAddress | null */
            const user = res.user
            return user
        }
    } catch(ex) {
        console.error('Token API Error', ex.message)
    }

    return null
}

/**
 * Check on our Backend DB if the wallets is whitelisted on the Guest Lists
 * @param {String} address
 */
export async function isOnGuestList(address) {
    try {
        const response = await fetch(`${TOKEN_API_URL}?action=findGuestList&address=${address}`);
        
        const res = await response.json();
        if (res && res.wallet) {
            /** @type string */
            const wallet = res.wallet
            return wallet
        }
    } catch(ex) {
        console.error('Token API Error', ex.message)
    }

    return null
}



export async function revealTokens(address, amount) {
    try {
        const response = await fetch(`${TOKEN_API_URL}?action=reveal&amount=${amount}&address=${address}`, {
            method: 'POST'
        });
        if (response) {
            const res = await response.json();
            if (res) {
                return true
            }
        }
    } catch(ex) {
        console.error('Reveal Backend Error:', ex)
    }

    return false
}


/**
 * Check if a token is marked as claimed in our DB
 * @param {number} token 
 * @returns 
 */
export async function isTokenClaimed(token) {
    try {
        const response = await fetch(`${TOKEN_API_URL}?action=getTokenClaimed&tokenId=${token}`);
        
        const res = await response.json();
        if (res && res.status) {
            /** @type string */
            const tokenStatus = res.status
            return tokenStatus === 'claimed'
        }
    } catch(ex) {
        console.error('Token API Error', ex.message)
    }

    return false
}