<script>
    export let content
    export let classes = ''
    export let target = ''
    export let title = ''
    export let link = 'https://magiceden.io/collections/ethereum/0x8Cfa6F29930E6310B6074baB0052c14a709B4741'
    export let onClick = (evt) => { evt.preventDefault(); } // default empty action

    let clipID = `btn-clip-path`
</script>

<a href={link} target={target} class="buy-button relative flex items-center justify-center {classes}" on:click={onClick} title={title}>
    <p>
        <span class="">{content}</span>
        <span class="">{content}</span>
    </p>
    <svg viewBox="0 0 279 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 0.5H278.5V51.5H17.6654L0.5 38.7486V0.5Z" stroke="white" stroke-width="2" />
    </svg>
</a>

<style lang="postcss">
    .buy-button {
        @apply min-w-[115rem] h-[24rem] md:min-w-[28rem] md:h-[5.7rem] bg-black overflow-hidden  2xl:h-[4.8rem] 2xl:min-w-[24rem];
        clip-path: url(#btn-clip-path)
    }

    .buy-button p {
        @apply flex flex-col shrink-0 overflow-hidden md:h-10 h-36 2xl:h-10;
    }

    .buy-button p span {
        @apply block translate-y-[-100%] text-9xl text-[11rem] md:text-[2.6rem] font-chakra-bold  2xl:text-4xl;
        transform-style: preserve-3d;
        transition: all .3s ease;
    }

    .buy-button:hover span {
        @apply translate-y-0
    }

    .buy-button svg {
        @apply absolute z-[2]
    }

    .buy-button:after {  
        content: "";
        @apply w-full h-0 bg-white absolute bottom-0;
        transition: all .3s ease;
        mix-blend-mode: difference;
    }
    .buy-button:hover:after {
        @apply h-full
    }
</style>