import { Howl } from "howler";

let assets = null

/**
 * Return an object with internal Howl instances
 * @returns {{[key]: {[key]: Howl} }}
 */
export const getSoundAssets = () => {
    if (!assets) {
        assets = {

            foundersMintOnboarding: {
                seeYouTyping: new Howl({ src: ["./assets/audio/seeYouTyping.wav"] }),
                // primaryBackground: new Howl({ src: ["./assets/audio/activate-founders-mint-primary-background.mp3"] }),
                // finalBackground: new Howl({ src: ["./assets/audio/activate-founders-mint-background.mp3"] }),
            },
            homepageBackground: new Howl({ src: ["./assets/audio/website-homepage-main-loop-v2.mp3"]}),
            mintBtnClick: new Howl({ src: ["./assets/audio/mint-click.mp3"] }),
            errorMint: {
                sprite: new Howl({ 
                    src: ["./assets/audio/error-mint-sprite.mp3"],
                    sprite: {
                        accessDenied: [330, 800],
                        accessAttemptFailed: [2000, 2125],
                        noNoNo: [5030, 3250],
                    },
                }),
            },
            burnBackgroundAudio: new Howl({
                src: ["./assets/audio/BACKGROUND-FINAL.mp3"],
                volume: 0.4
            }),
        }
    }

    return assets
}
