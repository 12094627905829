<script>
    import { onMount } from "svelte";
    import { sineIn } from "svelte/easing";
    import { fade } from "svelte/transition";
    import { isDebug } from "../utils/Store";
    import { debounce } from "../utils/Debounce";
    import { preloadVideo, reverseVideo } from '../utils/VideoUtils'
    import { stopBackgroundAudio } from '../utils/audio/soundUtils'
    import Wallet from '../utils/Wallet'

    let videoInRef
    let videoLoopRef
    let videoOutRef
    let processingVideoRef
    
    // Flag to show a modal to ask for gas payment confirmation
    let askForGasPay = false

    let currentVideo = 'in'
    // Flag to toggle between different videos

    // flag to allow click on video
    let allowVideoClick = false

    let showCounter = false
    let showLoader = false
    let showError = false

    // Counter of token Ids to reveal
    let counter = 1
    let maxTokens = 0
    let revealing = false

    // flag to control timeouts
    let timeToPreloadVideo

    // Base URL for videos
    const baseVideo = '/assets/videos/reveal/'

    onMount(() => {
        setupVideoSrc()
        updateTokensCounter()
        handleGlobalAudio()

        const deb = debounce(setupVideoSrc)
        window.addEventListener('resize', deb)
        return () => {
            videoInRef.loop = false
            videoInRef.onended = null
            videoInRef.onplaying = null
            allowVideoClick = false
            clearTimeout(timeToPreloadVideo)
            window.removeEventListener('resize', deb)
            window.removeEventListener('audioChange', onAudioChange)
        }
    })


    function handleGlobalAudio() {
        const audioIcon = document.querySelector('.onMusicSmall')
        if (audioIcon) {
            stopBackgroundAudio()
            muteAudioVideos(false)
        } else {
            muteAudioVideos(false)
        }

        window.addEventListener('audioChange', onAudioChange)
    }

    function onAudioChange(evt) {
        muteAudioVideos(!evt.detail.enabled)
    }


    function muteAudioVideos(muted) {
        // console.log('muted [%s]:', currentVideo, muted)
        videoInRef.muted = muted
        videoOutRef.muted = muted
        videoLoopRef.muted = muted
    }


    async function updateTokensCounter() {
        const tokens = await Wallet.getTokensMinted()
        console.log('Max tokens:', tokens)
        maxTokens = tokens.length

        if (counter>maxTokens) {
            counter = maxTokens
        }
    }

    function setupVideoSrc() {
        currentVideo = 'in'
        let srcVideo = 'intro-h.mp4'
        let loopVideoSrc = 'loop-h.mp4'

        if (window.innerWidth < window.innerHeight) {
            srcVideo = 'intro-v.mp4'
            loopVideoSrc = 'loop-v.mp4'
        }

        videoInRef.currentTime = 0
        videoInRef.src = baseVideo + srcVideo
        videoInRef.onplaying = () => {
            preloadVideo(baseVideo + loopVideoSrc).then(srcBlob => {
                videoLoopRef.src = srcBlob
            })

            // After intro video is playing, enable the counter
            allowVideoClick = true
            timeToPreloadVideo = setTimeout(() => {
                preloadOutVideo()
                showCounter = true
            }, 2000)
        }
        videoInRef.onended = () => {
            currentVideo = 'loop'
            videoLoopRef.play()
        }

        videoInRef.play()

        videoOutRef.onended = () => {
            currentVideo = 'none'
            allowVideoClick = false
        }
    }

    async function preloadOutVideo() {
        console.log('preload out!')
        // Do nothing in case the component is unmounted
        if (!videoOutRef) {
            return
        }
        
        let outVideoSrc = 'outro-h.mp4'
        if (window.innerWidth < window.innerHeight) {
            outVideoSrc = 'outro-v.mp4'
        }

        if (!videoOutRef.src) {
            const blobOutro = await preloadVideo(baseVideo + outVideoSrc)
            videoOutRef.src = blobOutro
        }
    }

    async function onRevealClick() {
        try {
            if (revealing) {
                return
            }
            revealing = true
            askForGasPay = true
            showLoader = true
            continuePayment()

        } catch(ex) {
            console.error('Reveal Error:', ex.reason || ex.message)
            revealing = false
        }
    }

    async function continuePayment() {
        try {
            showLoader = true
            // play video in yoyo loop after it has been rendered on the UI
            setTimeout(() => {
                processingVideoRef && reverseVideo(processingVideoRef) // play video in yoyo loop
            }, 500)

            const tokensAmount = counter===maxTokens ? 0 : counter
            await Wallet.migrateFront(tokensAmount)
            videoOutRef.play()
            videoLoopRef.pause()
            currentVideo = 'out'
            updateTokensCounter()
        } catch(ex) {
            console.error('Reveal Error:', ex.reason || ex.message)
            revealing = false
        } finally {
            showLoader = false
            askForGasPay = false
        }
    }

    function incCounter() {
        if (!revealing && counter < maxTokens) {
            counter++
        }
    }

    function decCounter() {
        if (!revealing && counter > 1) {
            counter--
        }
    }


    function closeModal() {
        askForGasPay = false;
        showError = false;
        revealing = false;
    }
</script>
<div class="mint-portal full-page bg-black absolute top-0 flex flex-col md:flex-row items-center justify-center z-30 overflow-scroll md:overflow-hidden w-full h-full">
    
    <div class="flex flex-col items-center relative md:aspect-video">
        <!-- svelte-ignore a11y-media-has-caption -->
        <video playsinline class:active={currentVideo==='in'} class="video w-full z-10 absolute hidden object-cover" bind:this={videoInRef} />
        <!-- svelte-ignore a11y-media-has-caption -->
        <video playsinline loop class:active={currentVideo==='loop'} class="video w-full z-10 absolute hidden object-cover" bind:this={videoLoopRef} />
        <!-- svelte-ignore a11y-media-has-caption -->
        <video playsinline class:active={currentVideo==='out'} class="video w-full z-10 absolute hidden object-cover" bind:this={videoOutRef} />

        {#if allowVideoClick && maxTokens>0}
            <div class="capsule-area flex flex-col items-center overflow-hidden">
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <!-- svelte-ignore a11y-no-static-element-interactions -->
                <div class="click-area custom-cursor" class:debug={$isDebug} on:click={onRevealClick}></div>

                {#if !revealing}
                <div class="counter max-md:mb-44 md:mt-20 flex flex-col" class:hidden={!showCounter} class:active={showCounter} title="Choose how many tokens to reveal">
                    <button class="plus btn rounded-t-full" class:disabled={counter===maxTokens} on:click={incCounter}>+</button>
                    <div class="number btn">{counter}</div>
                    <button class="minus btn rounded-b-full" class:disabled={counter===1} on:click={decCounter}>-</button>
                </div>
                {/if}
            </div>

        {/if}
    </div>

    {#if askForGasPay || showError}
    <div transition:fade={{ duration: 150, easing: sineIn }}
    class="absolute top-0 bottom-0 left-0 right-0 grid justify-center items-center">
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div on:click={closeModal} class="absolute top-0 bottom-0 left-0 right-0 bg-neutral-900/90" style="z-index: 100;" />
        <div class="relative rounded shadow-sm w-[80vw] md:w-[600px] h-auto bg-neutral-800 text-white max-md:text-8xl md:text-3xl p-20 text-center z-[999] font-press-start" >
            
            {#if showError}
                <div class="my-32 md:my-10">The reveal cannot be completed, please try again</div>
                <button
                    class="mx-auto bg-neutral-100 hover:bg-white text-neutral-800 rounded-3xl p-20 md:p-10 focus:bg-neutral-300 max-md:leading-[1.3]"
                    on:click={closeModal}
                >
                    OK
                </button>
            {:else}
                {#if showLoader}
                    <div class="my-40 md:my-10">PROCESSING...</div>
                    <video preload="auto" bind:this={processingVideoRef} disableRemotePlayback playsinline muted id="preloader-video" class="video w-auto max-md:w-full md:h-[40rem] mx-auto">
                        <source src="/assets/videos/preloader.mov" type="video/mp4; codecs=&quot;hvc1&quot;">
                        <source src="/assets/videos/preloader.webm" type="video/webm">
                    </video>
                {:else}
                    <!-- <div class="max-md:mt-40 md:mt-5">GAS FEES ARE CRAZY!</div>
                    <div class="my-20 md:my-6 max-md:leading-[1.3]">ANYTHING UNDER 40 GWEI WE’LL COVER</div>
                    <div class="mb-60 md:mb-16 max-md:leading-[1.3]">YOU SURE YOU DON’T WANT<br/>TO WAIT?</div>
                    <div class="buttons w-[90%] mt-20 mx-auto flex flex-col">
                        <button
                            class="mx-auto bg-neutral-100 hover:bg-white text-neutral-800 rounded-3xl p-20 md:p-10 focus:bg-neutral-300 max-md:leading-[1.3]"
                            on:click={continuePayment}
                        >
                            YES, I’M SURE
                        </button>
                        <button class="mt-52 md:mt-20 text-neutral-200 hover:text-white" on:click={closeModal}>NO, I’LL WAIT </button>
                    </div> -->
                {/if}
            {/if}
            
        </div>
    </div>
    {/if}

</div>


<style>
    .video {
        @apply w-[96%] md:aspect-video;
    }
    .video.active {
        @apply relative z-20 block;
    }

    .counter .btn {
        border: 1px solid #FFF;
        @apply flex items-center justify-center w-72 h-64 md:w-24 md:h-20 text-9xl md:text-5xl bg-white/10 hover:bg-white/20 font-bold
    }

    .counter .btn.disabled {
        @apply text-gray-500
    }

    .counter {
        @apply translate-x-[150%];
        transition: all .3s ease;

    }
    .counter.active {
        @apply translate-x-0;
    }

    .capsule-area {
        @apply absolute z-30 md:h-[49rem] w-80 md:w-52 md:right-16 right-28 translate-y-[119%] md:translate-y-[45%] max-md:flex-col-reverse
    }

    .click-area {
        @apply w-full h-[30vw] md:h-[18vw]
    }

    .click-area.debug {
        @apply bg-red-200 bg-opacity-20 shadow-md shadow-red-500 rounded-3xl;
    }
</style>