<script>
    import { showLowPowerMode } from "../utils/Store"

    export let onClick = null

    function onClose(evt) {
        showLowPowerMode.set(false)
        
        if (onClick) {
            onClick(evt)
        }
    }
</script>

<div class="low-battery-popup">
    <div class="low-battery-popup-header">
        <img src="/assets/images/logo.svg" alt="logo" class="absolute top-32 left-32 w-96" />
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
        <img src="/assets/images/close.svg" alt="" class="low-battery-popup-close absolute top-32 right-32" on:click={onClose} />
    </div>
    <p class="text-[5.5rem] leading-[8rem]  uppercase tracking-widest font-type-regular">
        Make sure that energy-saving mode is switched off.
    </p>
    <img src="/assets/images/low-battery.svg" alt="low-power-mode" class="my-44" />
    <p class="uppercase tracking-widest font-type-regular text-8xl leading-[8rem]">
        after disabling the mode, please reload the page
    </p>
</div>

<style>
    .low-battery-popup {
        @apply fixed z-[90] w-full h-screen bg-black flex flex-col justify-center items-center px-40 text-center relative;
        color: #E4E6EB;
    }
</style>