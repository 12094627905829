<script>
// @ts-nocheck

    import { onMount } from 'svelte'
    import { get } from 'svelte/store'
    import { activeSection, totalSections } from '../utils/Store';

    let bar

    onMount(() => {
        activeSection.subscribe(val => {
            const percentaje = (val-1) * 100 / (get(totalSections)-1)
            // console.log(percentaje)
            bar && (bar.style.height = `${percentaje}%`)
        })
    })

    function handleNavClick(indexSection) {
        console.log('Scroll to section:', indexSection+1)
        const root = document.querySelector('.scroll-container')
        root.scroll({
            top: window.innerHeight * (indexSection+1),
            behavior: 'smooth'
        })
    }
</script>

<div class="progress hidden md:flex fixed z-40 right-0 items-center justify-end">
    <div class="progress-buttons flex flex-col items-center justify-between">
        {#each Array($totalSections) as _, i}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-static-element-interactions -->
            <div class="button custom-cursor" class:active={(i+1)===$activeSection} on:click={() => { handleNavClick(i) }}></div>
        {/each}
    </div>
    <div class="progress-lines flex flex-col items-center justify-start shrink-0">
        {#each Array($totalSections) as _, i}
        <div class="line"></div>
        {/each}
    </div>
    <div class="progress-line">
        <div class="progress-line-inner" bind:this={bar}> </div>
    </div>
</div>


<style>
    .progress {
        top: 0;
        @apply translate-y-2/3 lg:translate-y-1/2 2xl:translate-y-1/3;
        --button-size: 3rem;
    }
    .progress-buttons {
        height: calc(var(--button-size) * 18);
        margin-right: calc(var(--button-size) * 0.3);
    }
    
    .button {
        width: var(--button-size);
        height: var(--button-size);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all ease .3s;
    }
    .button:before {
        content: "";
        background: #fff;
        border-radius: 50%;
        width: calc(var(--button-size) / 2.5);
        height: calc(var(--button-size) / 2.5);
        position: absolute;
    }
    .button:after {
        content: "";
        background: 0 0;
        border-radius: 50%;
        border: 1px solid #fff;
        -webkit-transition: all .3s ease;
        transition: all .4s ease;
        opacity: 0;
    }
    .button:hover:after {
        opacity: 1;
        width: calc(var(--button-size) * 0.75);
        height: calc(var(--button-size) * 0.75);
    }
    .button.active:after {
        opacity: 1;
        width: calc(var(--button-size) * 1);
        height: calc(var(--button-size) * 1);
    }

    .progress-lines {
        height: calc(var(--button-size) * 18);
        margin-right: calc(var(--button-size) * 0.5);
    }

    .line {
        width: calc(var(--button-size) * 0.5);
        height: 0.2rem;
        background-color: #fff;
        margin-bottom: calc(var(--button-size) * 2.06);
    }
    .line:first-child {
        margin-top: 1.3rem;
    }
    .line:last-child {
        margin-bottom: 0;
    }

    .progress-line {
        height: calc(var(--button-size) * 18);
        width: 0.2rem;
        margin-right: calc(var(--button-size) * 1);
        background-color: #3f3f3f;
    }

    .progress-line-inner {
        width: 100%;
        height: 0;
        background-color: #fff;
        border-radius: 1rem;
        transition: height ease .3s;
    }
</style>