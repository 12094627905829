// @ts-nocheck
import { activeSection } from './Store';

/**
 * Enable or disable animations on .text-in elements
 * @param {HTMLElement} node
 * @param {Boolean} addAnimation 
 */
function toggleTextAnimation(node, addAnimation) {
    // console.log('ANIME:', node.innerHTML, addAnimation)
    const animationVlaue = addAnimation ? 'slideUp 0.75s ease-in both' : 'none'
    
    if (node.classList.contains('text-in')) {
        node.style.animation = animationVlaue;
    } else {
        for (const child of node.children) {
            if (child.classList.contains('text-in')) {
                child.style.animation = animationVlaue;
            }
        }
    }
}


/**
 * 
 * @param {HTMLElement} root 
 * @param {Number} indexSection 
 */
export const setupObserver = (root, indexSection, callback) => {
    if (!root) {
        return
    }
    
    const observer = new IntersectionObserver((entries) => {

        entries.forEach(async entry => {
            try {
                /** @type HTMLElement */
                const node = entry.target
                // console.log(indexSection, 'Node?', node.tagName, entry.intersectionRatio)
                
                /** @type HTMLVideoElement */
                let videos = node.parentElement?.parentElement?.querySelectorAll('video') // node.parentElement.querySelectorAll('video')
    
                const animations = node.parentElement.querySelectorAll('.text-in')
                // indexSection===1 && console.log(animations)
                
                // example from https://codepen.io/designcourse/pen/RXVdBj
                if (entry.intersectionRatio > 0) {
                    
                    if (videos) {
                        for (const video of videos) {
                            // Add video class to apply scale styles after video is detected for the first time
                            !video.classList.contains('video-'+indexSection) && video.classList.add('video-'+indexSection)
                            await video.play()
                        }
                    }
                    animations.forEach(child => {
                        toggleTextAnimation(child, true)
                    })
                    activeSection.set(indexSection)
                    callback && callback(true)
                }
                else {
                    if (videos) {
                        videos.forEach(video => {
                            video.currentTime = 0 // always reset to 0
                            video.pause()
                        })
                    }
                    // toggleTextAnimation(node, false)
                    animations.forEach(child => {
                        toggleTextAnimation(child, false)
                    })
                    callback && callback(false)
                }

            } catch(ex) {
                console.trace('Observer', ex.message)
                // TODO: Reload page
            }
        })
    });

    // Observer only H2 titles on each section
    for (const el of root.querySelectorAll('h2')) {
        observer.observe(el)
    }

}