<script>
  // 1728 x 1117
  import { onMount } from "svelte";
  import { setupVideoSrc } from "./utils/VideoUtils"
  import { setupObserver } from "./utils/Observer"
  import { showLowPowerMode, burnActive, burnError, mintActive, revealActive, isDebug, totalSections, isPublicMintOpen } from "./utils/Store"

  // UI Elements
  import ProgressBar from './components/ProgressBar.svelte'
  import LowPowerMode from './components/LowPowerMode.svelte'
  import AudioPopup from './components/AudioPopup.svelte'
  import Header from './components/Header.svelte'
  import RevealPortal from './mint/RevealPortal.svelte'

  // import { loadWebGlAnimations } from "./utils/PixiAnimation";
  
  let scrollArea

  /** @type HTMLVideoElement */
  let bgVideo

  // Dynamic component load
  let AccessDenied = null
  let BurnPortal = null

  // temp ref t force preload this video
  let publicMintVideoRef

  const sections = []

  onMount(initApp)
  
  function initApp() {
    window.scrollTo({ top: 0 })
    setupVideoSrc(bgVideo)
    setupObserver(null, 0)
    $isDebug && console.log('setup video and observer!')
    
    bgVideo.play().catch(err => {
      err && console.error('Low Power Mode!', err.message)
      if (err.message.includes('The request is not allowed by the user agent')) {
        showLowPowerMode.set(true)
      }
    })
    
    // Dynamic load of internal scroll sections
    loadScrollSections()

    burnError.subscribe(async val => {
      if (val && AccessDenied===null) {
        AccessDenied = (await import("./mint/AccessDenied.svelte")).default
      }
    })

    burnActive.subscribe(async val => {
      if (val && BurnPortal===null) {
        BurnPortal = (await import("./mint/BurnPortal.svelte")).default
      }
    })

    mintActive.subscribe(async val => {
      // if (val && MintPortal===null) {
      //   MintPortal = (await import("./mint/MintPortal.svelte")).default
      // }
    })

  }

  // Click when user close the Low Power Mode
  function onUserClick(evt) {
    if ($showLowPowerMode) {
      bgVideo.play()
      // use the same click event to enable all videos
      const videos = scrollArea.querySelectorAll('video')
      for (const v of videos) {
        v.play().then(() => { v.pause() })
      }
      hidePreloader()
    }
  }
  
  async function loadScrollSections() {
    $isDebug && console.log('Start to load internal sections')
    // load only the first three sections
    const MODULES_TO_LOAD = window.innerWidth < 640 ? 4 : $totalSections
    // On desktop devices load everything from the start.
    // On Mobile devices, load only 3 sections and then, when user do the first scroll, load the rest of sections
    for(let i=0; i<=MODULES_TO_LOAD; i++) {
      let index = `${i}`.padStart(2, '0')
      sections[i] = (await import(`./sections/${index}.svelte`)).default
    }

    // Wait 500ms until all bg is really ready!
    if (window['startAppTime']) {
      const endTime = Date.now() - window['startAppTime']

      // Time to play preload video
      const introTime = 3200
      
      // Calculate the minimum time to wait until the preloader video is played at least one time
      let waitTime = (endTime < introTime && location.hostname!=='localhost') ? introTime - endTime : 0
      $isDebug && console.log('Wait time:', waitTime)

      if (waitTime > 0) { setTimeout(hidePreloader, waitTime) }
      else { hidePreloader() }
    } else {
      let waitTime = $showLowPowerMode ? 0 : 3000
      setTimeout(hidePreloader, waitTime)
      // console.log('NO startTimeFound, loading app in', waitTime)
    }
  
  
    // Add scroll listener only when we are on mobile
    if (MODULES_TO_LOAD < $totalSections) {
      scrollArea.addEventListener('scroll', onScrollArea)
    }

    // loadWebGlAnimations()
  }

  function hidePreloader() {

    const preloader = document.getElementById('preloader-div')
    if (preloader) {
      /** @type HTMLElement */
      const curtain = preloader.querySelector('.preloader-curtain')
      curtain.classList.add('slide-up-now')
      curtain.style.setProperty('--curtain-opacity', '1')

      // Wait one second...
      setTimeout(() => {
        preloader.style.background = 'transparent'
        
        /** @type HTMLVideoElement */
        const video = preloader.querySelector('.video')
        video.style.display = 'none'
        curtain.style.opacity = '0'

        // wait a little bit more before remove the preloader
        setTimeout(() => {
          preloader.remove()
        }, 950)
      }, 1000)
    }
  }

  let adding = false
  /** When user scroll for the first time, we start to load pending sections **/
  async function onScrollArea() {
    if (adding || sections.length > 5) {
      scrollArea.removeEventListener('scroll', onScrollArea)
      return
    }
    adding = true
    // console.log('Loading more areas', sections.length)
    scrollArea.removeEventListener('scroll', onScrollArea)

    // Load pending sections
    for (let i=4; i<=$totalSections; i++) {
      let index = `${i}`.padStart(2, '0')
      sections[i] = (await import(`./sections/${index}.svelte`)).default
    }
  }
</script>

<div class="relative">
  <!--  Generic Clip Path for all ButtonEffect Instances -->
  <svg class="svg absolute">
    <clipPath id="btn-clip-path" clipPathUnits="objectBoundingBox">
        <path d="M0.002,0.01 H1 V1 H0.064 L0.002,0.76 V0.01"></path>
    </clipPath>
  </svg>


  {#if $showLowPowerMode}
    <LowPowerMode onClick={onUserClick} />
  {/if}
  
  <Header />

  <!-- Background Video -->
  <video bind:this={bgVideo} playsinline muted loop class="background-video absolute z-0 top-0 left-0 w-full h-full pointer-events-none object-cover">
    <source data-desktop-src="assets/videos/sections/lulubg-desktop.mov" data-mobile-src="assets/videos/sections/lulubg-mobile.mov" type="video/mp4; codecs=hvc1"/>
    <source data-desktop-src="assets/videos/sections/lulubg-desktop.webm" data-mobile-src="assets/videos/sections/lulubg-mobile.webm" type="video/webm"/>
  </video>

  <!-- Main Scroll area -->
  {#if !$burnError && !$burnActive && !$revealActive}
    <main class="scroll-container relative bg-[rgba(0,0,0,0.35)]" bind:this={scrollArea}>
      {#if !$mintActive}
        {#each sections as section, i}
          <section id="section{i}" class="scroll-area"><svelte:component this={section} /></section>
        {/each}
      {/if}
    </main>
    
    {#if window.innerWidth > 767 && !$mintActive && !$revealActive}
      <ProgressBar />
    {/if}
  {:else}
    <img class="footer-logo-always" src="/assets/images/footer-logo.svg" alt="Powered By ODLabs" />
  {/if}

  <!-- Burn Error -->
  {#if $burnError}
    <svelte:component this={AccessDenied} />
  {/if}
  
  <!-- Burn Portal -->
  {#if $burnActive}
    <!-- <svelte:component this={BurnPortal} /> -->
  {/if}

  <!-- Mint Portal -->
  {#if $mintActive && !$revealActive}
    <!-- <MintPortal /> -->
  {/if}

  {#if $revealActive && !$mintActive}
    <RevealPortal />
  {/if}

  <AudioPopup onFirstClick={onUserClick} />
</div>


<style>
  .scroll-container,
  .scroll-area {
    font-size: 60px;
  }
  
  .scroll-container {
    overflow: auto;
    -webkit-scroll-snap-type: y mandatory;
        -ms-scroll-snap-type: y mandatory;
            scroll-snap-type: y mandatory;
  }
  
  .scroll-area {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
  
  .scroll-container,
  .scroll-area {
    margin: 0 auto;
  }
  
  .scroll-area {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e4e6eb;
    overflow: hidden;
  }

  .footer-logo-always {
        @apply md:fixed md:bottom-9 md:left-12 md:h-[2.6vw] h-64 z-40
    }
</style>
