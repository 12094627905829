import { createWeb3Modal as createDefaultModal, defaultConfig } from '@web3modal/ethers5'

function createWeb3Modal() {
    // 1. Get projectId from WalletConnect:
    // https://cloud.walletconnect.com/app
    const projectIdB64 = 'OTZhNWMwZjRkZjliMzdlYjJkNDViNmY2MWRiNDkzNDc='

    // 2. Set chains
    const mainnet = {
        chainId: 1,
        name: 'Ethereum',
        currency: 'ETH',
        explorerUrl: 'https://etherscan.io',
        rpcUrl: 'https://cloudflare-eth.com'
    }
    const testnet = {
        chainId: 11155111,
        name: 'Sepolia',
        currency: 'ETH',
        explorerUrl: 'https://sepolia.etherscan.io',
        rpcUrl: 'https://ethereum-sepolia-rpc.publicnode.com'
    }

    // 3. Create modal
    const metadata = {
        name: 'ODLabs',
        description: 'The First On-Chain Generative Mixed Reality Companion',
        url: 'https://odlabs.io', // origin must match your domain & subdomain
        icons: ['https://odlabs.io/']
    }

    const web3Modal = createDefaultModal({
        ethersConfig: defaultConfig({ metadata }),
        chains: [mainnet],
        // chains: [testnet],
        projectId: window.atob(projectIdB64),
        enableAnalytics: location.hostname.includes('odlabs.io'), // Optional - defaults to your Cloud configuration
        featuredWalletIds: [ // https://explorer.walletconnect.com/
            'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // Metamask ID
            // 'a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393', // Phantom ID
        ]
    })

    return web3Modal
}

export { createWeb3Modal }