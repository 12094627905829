<script>
    import { slide } from 'svelte/transition';
	import { quintOut } from 'svelte/easing';
    import ButtonEffect from "./ButtonEffect.svelte";
    import { isWalletConnected, burnError, showWallet, revealActive, mintActive, showMintSuccessful, isPublicMintOpen } from '../utils/Store'
    import wallet from '../utils/Wallet'
    // import { isOnWhitelist } from '../utils/Backend'

    let walletText = 'CONNECT WALLET'
    let userValid
    let isValidatingUser = false

    // Flag to toggle features based on screen size
    let innerWidth = 0
    let innerHeight = 0
    $: isMobile = innerWidth * 1.33 <= innerHeight

    // On desktop devices show a QR code to download the app
    let showDesktopQR = false

    /**
     * Connect Wallet here
     * @param {PointerEvent} evt
     */
    async function enableWallet(evt) {
        evt.preventDefault()
        
        wallet.getConnection(async (isConnected) => {
            console.log('Connected?', isConnected)
            isWalletConnected.set(isConnected)
            
            walletText = isConnected ? 'CONNECTED' : 'CONNECT WALLET'

            if (isConnected && !isValidatingUser) {
                isValidatingUser = true
                const address = wallet.getUserAddress()
                userValid = await wallet.isAddressOnWhitelist(address) // Check on Smart Contract
                isValidatingUser = false
            }

            if (!isConnected) {
                userValid = false
                burnError.set(false)
                mintActive.set(false)
                revealActive.set(false)
                showMintSuccessful.set(false)
            }
        });
    }


    let minting = false
    async function mintClick() {
        if (minting) {
            return true
        }
        // flag to avoid duplicated clicks!
        minting = true

        showMintSuccessful.set(false)
        revealActive.set(false)

        // We are a valid user or we are on the public mint, so anyone can mint
        if (userValid || $isPublicMintOpen) {
            mintActive.set(true)
            minting = false
            return
        }

        // TODO: What will happen now??
        burnError.set(true) // ?????
        minting = false
    }

    let revealing = false
    async function revealClick() {
        if (revealing) {
            return true
        }
        // flag to avoid duplicated clicks!
        revealing = true;

        revealActive.set(true)
        mintActive.set(false)

        setTimeout(() => {
            revealing = false;
        }, 1000)
    }


    function openModalQR(evt) {
        if (!isMobile) {
            evt.preventDefault()
            showDesktopQR = !showDesktopQR
        }
    }
  
</script>

<svelte:window bind:innerWidth bind:innerHeight />

<header class="fixed top-16 md:top-14 z-50 w-full flex justify-end max-md:bg-black">
    <div class="absolute left-28 md:left-14 top-20 md:top-0 flex md:items-center">
        <a href="/" class="w-96 md:w-48 mr-28 md:mr-14">
            <img src="assets/images/logo.svg" alt="ODLabs Logo" class="w-full" />
        </a>

        <div class="overflow-hidden relative">
            <div class="btn-portals flex flex-col md:flex-row translate-x-[-101%]" class:active={$isWalletConnected && !$revealActive}>
                <button 
                    on:click={revealClick}
                    class="sound-click font-type-bold text-9xl md:text-5xl z-50 overflow-hidden hover:bg-white hover:text-black py-2 max-md:mb-8 md:mr-4">
                    REVEAL
                </button>
                <!-- <button 
                    on:click={mintClick}
                    class="sound-click font-type-bold text-9xl md:text-5xl z-50 overflow-hidden hover:bg-white hover:text-black py-2">
                    MINT
                </button> -->
            </div>
            
            {#if $isWalletConnected}
                <p class="invisible absolute font-rajdhani-bold">Placeholder Title to force load font </p>
                <p class="invisible absolute font-rajdhani-medium">Placeholder Content to force load font </p>
                <p class="invisible absolute font-roboto-bold">Placeholder to force load font Roboto Bold</p>
                <p class="invisible absolute font-roboto-medium">Placeholder to force load font Roboto</p>
            {/if}
        </div>
    </div>


    {#if $showWallet}
        <div class="md:mr-14 flex flex-col md:flex-row relative z-[99]">
            <ButtonEffect content={walletText} classes="connect sound-click px-10" onClick={enableWallet} />

            <ButtonEffect content="Download App" classes="connect sound-click px-10 uppercase max-md:absolute max-md:top-80" link="https://app.odlabs.io/" target='_blank' onClick={openModalQR} />

            {#if showDesktopQR}
            <!-- svelte-ignore a11y-click-events-have-key-events a11y-no-static-element-interactions -->
            <div class="absolute z-[99] flex flex-col w-96 top-[4vw] right-0 px-6 py-6 bg-neutral-800/80" transition:slide={{ delay: 0, duration: 300, easing: quintOut, axis: 'y' }} on:click={openModalQR}>
                <em class="arrow"></em>
                <div class="w-full min-h-96">
                    <h3 class="text-4xl text-center mb-4 font-chakra-regular">Scan the QR to Download the App</h3>
                    <img src="./assets/images/qr-app.png" alt="Scan to download app" class="w-full aspect-square">
                </div>
            </div>
            {/if}
        </div>
    {/if}

</header>


<style>
    :global(header .buy-button) {
        transform: scale(0.85) translateX(2rem);

        @media screen and (max-width:768px) {
            transform: scale(0.65) translateX(18rem);
        }
    }

    button {
        transition: all .5s ease;
        border: 1px solid white;
        /* padding: 10px 20px; */
        @apply px-8 py-8 md:py-2 md:pb-3 md:px-4
    }

    .btn-portals {
        transition: all .3s ease;
    }

    .btn-portals.active {
        @apply translate-x-0
    }

    em.arrow {
        @apply absolute left-1/2 top-[-9px] block overflow-hidden bg-transparent;
        border-left: 9px dashed transparent;
        border-right: 9px dashed transparent;
        border-bottom: 9px solid rgb(38 38 38 / 0.8);
        transform: translate(-50%);
    }
</style>