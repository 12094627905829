export function stopBackgroundAudio() {

    /** @type HTMLMediaElement */
    const audio = document.getElementById('background-audio')
    // @ts-ignore
    audio.pause()

    return audio.paused
}

export function loadHowlerSounds(sounds) {
    const soundLoadingObjects = sounds.map((sound) => {
        return new Promise((resolve, reject) => {
            const loadActiveStates = ["loading", "loaded"];

            if (!loadActiveStates.includes(sound.state())) {
                sound.load();
            }

            sound.once("load", resolve).once("loaderror", reject);
        });
    });

    return Promise.all(soundLoadingObjects);
}

function secToMs(seconds) {
    return seconds * 1000;
}

/**
 * @typedef {Object} SoundOptions
 * @property {string} [sprite] - the name of the sprite (chunk of the sound) to play
 * @property {number} [volume] - the volume of the sound (from 0 to 1)
 * @property {boolean} [loop] - whether the sound should loop
 * @property {number} [rate] - the rate of the sound, from 0 (no play) to 1+ (normal speed and higher)
 * @property {Object} [fade] - the fade options
 * @property {number} [fade.inPercent] - the percentage of the sound duration to fade in
 * @property {number} [fade.outPercent] - the percentage of the sound duration to fade out
 * 
 * @param {Howl} sound 
 * @param {SoundOptions} options 
 * @returns {Howl}
 */
export function playSound(sound, options = {}) {
    let id = null;

    if ('sprite' in options) {
        sound.stop();
        id = sound.play(options.sprite);
    } else {
        id = sound.play();
    }

    if ('volume' in options) {
        console.log('setVolume', options.volume, id)
        sound.volume(options.volume, id);
    }

    if ('loop' in options && !('fade' in options)) {
        sound.loop(options.loop, id);
    }

    if ('rate' in options) {
        sound.rate(options.rate, id);
    }

    try {
        if ('fade' in options) {
            // console.trace('Fade: ', id, sound, options)
            const duration = sound.duration(id);
            const fadeInEndPointMs = secToMs(duration * (options.fade.inPercent / 100));
    
            sound.fade(0, 1, fadeInEndPointMs, id);
    
            if (options.fade.outPercent) {
                const fadeOutDurationMs = secToMs(duration * (options.fade.outPercent / 100));
                const fadeOutStartPointMs = secToMs(duration) - fadeOutDurationMs;
                setTimeout(() => {
                    sound.fade(1, 0, fadeOutDurationMs, id);
                }, fadeOutStartPointMs);
            }
    
            if (options.loop) {
                sound.once("end", async () => {
                    playSound(sound, options);
                });
            }
        }
    } catch(ex) {
        console.error('Fade Error', sound._src, options)
    }

    return sound;
}