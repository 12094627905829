const isDesktop = window.innerWidth > 620

export function setupVideoSrc(video) {
    let sources = video.querySelectorAll('source')
    for (let j = 0; j < sources.length; j++) {
        // console.log('setupVideo', sources[j], sources[j].dataset)
        if (isDesktop && sources[j].dataset.desktopSrc) {
            sources[j].src = sources[j].dataset.desktopSrc
        } else if (sources[j].dataset.mobileSrc) {
            sources[j].src = sources[j].dataset.mobileSrc
        }
    }
}

// Force preload video
export function preloadVideo(videoURL) {
    // taken from https://dinbror.dk/blog/how-to-preload-entire-html5-video-before-play-solved/
    return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open('GET', videoURL, true);
        req.responseType = 'blob';

        req.onload = function() {
            // Onload is triggered even on 404
            // so we need to check the status code
            if (this.status === 200) {
                var videoBlob = this.response;
                var vid = URL.createObjectURL(videoBlob); // IE10+
                // Video is now downloaded
                // and we can set it as source on the video element
                resolve(vid)
            } else {
                reject('Video not found!')
            }
        }
        req.onerror = function(err) { reject(err) }
        req.send();
    })
}


export function reverseVideo(video) {
    // const video = document.getElementById("preloader-video");
    let playbackInterval;
  
    const waitUntilEnd = () => {
        video.play();
        video.addEventListener("ended", () => {
            if (!playbackInterval) waitUntilBeginning();
        },
        { once: true }
        );
    };

    const waitUntilBeginning = () => {
        video.pause();
        video.currentTime = video.duration;

        let crtTime = video.duration;

        const reverse = function () {
        crtTime = Math.max(0, crtTime - 0.1);
        video.currentTime = crtTime;

        if (video.currentTime <= 0 || video.currentTime >= video.duration) {
            clearInterval(playbackInterval);
            playbackInterval = null;
            waitUntilEnd();
        }
        };
        reverse();
        playbackInterval = setInterval(reverse, 50);
    };

    waitUntilEnd();
  
    // video.addEventListener("loadeddata", function () { 
    // }, { once: true } ;
}


/**
 * 
 * @param {HTMLVideoElement} videoRefIntro 
 * @param {HTMLVideoElement} videoRefLoop 
 */
export function setupToggleVideos(videoRefIntro, videoRefLoop) {
    if (videoRefIntro.querySelector('source').src.includes('compu')) {
        videoRefIntro.ontimeupdate = () => {
            // console.log('time:', videoRefIntro.currentTime)
            if (videoRefIntro.currentTime >= 3.1) {
                swapVideos()
            }
        }
    } else {
        videoRefIntro.onended = swapVideos
    }
    async function swapVideos() {
        videoRefLoop.play()
        videoRefLoop.currentTime = 0
        videoRefIntro.pause()
        showVideo(videoRefLoop)
        hideVideo(videoRefIntro)
        videoRefIntro.currentTime = 0
    }

    const observer = new IntersectionObserver((entries) => {
        const node = entries.at(0)

        if (node.isIntersecting) {
            videoRefIntro.play()
        } else {
            videoRefLoop.pause()
            videoRefIntro.pause()
            showVideo(videoRefIntro)
            hideVideo(videoRefLoop)
        }
    })
    observer.observe(videoRefIntro)
}

function showVideo(ref) {
    ref.classList.remove('absolute')
    ref.classList.remove('invisible')
}

function hideVideo(ref) {
    ref.classList.add('absolute')
    ref.classList.add('invisible')
}
